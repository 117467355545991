import { useRouteError } from "react-router-dom";
import React from 'react';
import { Link} from "react-router-dom";
import NavBar from './components/navbar.tsx';
import { Text, Center, ChakraProvider} from '@chakra-ui/react'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <ChakraProvider >
        <NavBar current_page={0} />
        <Center>
        <Text fontWeight='medium' fontSize='3xl'>
        404 Error! Page not found! Please go back where you came from :)
      </Text>
        </Center>
    </ChakraProvider>
  );
}