import { useState, useEffect } from 'react';
import React from 'react';
import NavBar from './components/navbar.tsx';
import BookCard from './components/bookcard.tsx';
import { Link, useLocation, useNavigate, Navigate} from "react-router-dom";
import { SearchIcon, AddIcon } from '@chakra-ui/icons'
import {Text,Tooltip,Slider,SliderTrack, SliderFilledTrack, SliderThumb, SliderMark,Checkbox, Popover,PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody,PopoverFooter,PopoverArrow,PopoverCloseButton,PopoverAnchor,IconButton, Tag, TagCloseButton, TagLabel, Center, Wrap, WrapItem, Flex, Select, InputGroup, Input, InputRightElement, VStack, ChakraProvider, HStack} from '@chakra-ui/react'

const fetch = require('node-fetch');

function Search() {
// protected!
const [books, setBooks] = useState([{}]);
const [bookCards, setBookCards] = useState(BookCard[""]);
const [searchInput, setSearchInput] = useState("");
const [selectOption, setSelectOption] = useState("title");
const [searchTagsList, setSearchTagsList] = useState([]);
const [isAvailableNow, setIsAvailableNow] = useState(false);
const [rating, setRating] = React.useState(0)

const location = useLocation();

// SETTING DATA
useEffect(() => {

  fetch('http://localhost:3001/getBookData')
  .then(response => response.json())
  .then(bookData => {
    setBooks([bookData][0]);
  })
  .catch(error => {
    console.log(error);
  });

}, [location]);


useEffect(() => {
  setBookCards(books.map((book, index) => (
    <BookCard key={index} isbn={book.isbn} title={book.title} author={book.authors} totalCopies={book.total_copies} availCopies={book.available_copies} tags={book.tags} rating={book.rating}/>
  )));
}, [books]);

useEffect(() => {
  console.log("searching books bc tags changed!")
  searchBooks(searchInput, selectOption, searchTagsList, isAvailableNow, rating);
}, [searchTagsList]);

if (localStorage.getItem("token") == null) {
  // not logged in!
  //navigate("/login");
  return <Navigate to={"/login"} replace />;
}



function BookCards({books}) {
  // Assuming you have an array of books

  return (
    <Flex width="100%" flex='1' gap='5' flexWrap='wrap' alignItems={'flex-start'} justifyContent={'left'}>
      {books}
      </Flex>
    
  );
}

// EVENT HANDLERS
function searchBooks(input: String, criteria: String, searchTagsList, isAvailableNow: Boolean, rating: Number) {
  if (books[0].tags != undefined && searchTagsList != undefined) {

  let search = books;
  
  console.log("searching books!")

  if (searchTagsList.length > 0) {
    search = search.filter(book => {
      var tag_list = book.tags.split(", ");
      return searchTagsList.every(tag => tag_list.includes(tag));
    });
  }

  if (isAvailableNow == true) {
    search = search.filter(book => book.available_copies > 0);
  }

  if (rating != -1) {
    search = search.filter(book => book.rating >= rating);
  }
  
if (input != "") {
  search = books.filter(book =>
    book.title.toLowerCase().includes(input.toLowerCase())
    );

    if (criteria != "title") {
      if (criteria == "isbn") {
        search = books.filter(book =>
          book.isbn.toLowerCase().startsWith(input.toLowerCase())
          );
      } else if (criteria == "author") {
        search = books.filter(book =>
          book.authors.toLowerCase().includes(input.toLowerCase())
          );
      }
    }
} 

setBookCards(search.map((book, index) => (
  <BookCard key={index} isbn={book.isbn} title={book.title} author={book.authors} totalCopies={book.total_copies} availCopies={book.available_copies} tags={book.tags} rating={book.rating}/>
)));
  }
}

const handleSearchBooks = e => {
  searchBooks(searchInput, selectOption, searchTagsList, isAvailableNow, rating);
}

const handleSearchInputChange = event => {
  setSearchInput(event.target.value);
  searchBooks(event.target.value, selectOption, searchTagsList, isAvailableNow, rating);
}

const handleSelectChange = (e) => {
  setSelectOption(e.target.value);
  searchBooks(searchInput, e.target.value, searchTagsList, isAvailableNow, rating);
};


// component functions
function SearchBar() {
  return(
<Flex width='100%' mb = "10px">

<InputGroup size='md'>
        <Input
            variant='filled'
            placeholder='Search'
            defaultValue={searchInput}
            onChange={handleSearchInputChange}
        />
        <InputRightElement width='4.5rem' _hover={{color: "#00A39C" }} onClick={handleSearchBooks}>
        <SearchIcon />
        </InputRightElement>
</InputGroup>
</Flex>
  );
}

function SearchTagsList() {
  
  const [forcedUpdate, forceUpdate] = React.useState(0);

  function handleCloseTag(tagName: string) {
    var updatedTagsList : string[];
    updatedTagsList = [];
    searchTagsList.forEach(tag => {
      if (tag != tagName) {
        updatedTagsList.push(tag);
      }
    });
    setSearchTagsList(updatedTagsList);
  }

  function tag(tagName: string) {
    return (
      <WrapItem>
        <Tag variant="outline" size="lg" key={tagName} colorScheme='cyan' borderRadius='full' mr="5px" mb="5px">
          <TagLabel>{tagName}</TagLabel>
          <TagCloseButton key={tagName}  a-key={tagName} onClick={() => {handleCloseTag(tagName)}}/>
        </Tag>
      </WrapItem>
    );
  }

  function InputAddTags(){

    if (books[0].tags != undefined) {
      const allTags = Array.from(new Set(books.flatMap(book => book.tags.split(", "))));
      const filteredTags = allTags.filter(tag => !searchTagsList.includes(tag));

      const addTag = e => {
        let tagName = e.target.value;
        
        console.log(tagName)
        if (tagName != "") {
          var updatedTagsList : string[];
          updatedTagsList = searchTagsList;
          updatedTagsList.push(tagName);
          setSearchTagsList(updatedTagsList);
          forceUpdate(forcedUpdate+1); 
          searchBooks(searchInput, selectOption, updatedTagsList, isAvailableNow, rating);
        } 
      }
      
      return(
        <Select placeholder="Select Option" variant='filled' onChange={addTag}>
          {filteredTags.map((tag, index) => (
            <option key={index} value={tag}>{tag}</option>
          ))}
        </Select>
      );
    }
  }

  return (
    <Wrap spacing="5px" mt="20px">
      {searchTagsList.map((tagName, index) => (
        tag(tagName)
      ))}

<Popover>
  <PopoverTrigger > 
  <IconButton aria-label='Add tags' size="sm" borderRadius='full' icon={<AddIcon />} />
  </PopoverTrigger>
  <PopoverContent width='200px'>
    <PopoverArrow />
    <PopoverBody>
      {InputAddTags() }
      </PopoverBody>
  </PopoverContent>
</Popover>


    </Wrap>
  );
}


function SliderThumbWithTooltip() {
  const [showTooltip, setShowTooltip] = React.useState(false)
  return (
    <Slider
      width="75%"
      id='slider'
      defaultValue={0} min={0} max={5} step={0.5}
      colorScheme='teal'
      onChange={(v) => {setRating(v);searchBooks(searchInput, selectOption, searchTagsList, isAvailableNow, v)}}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <SliderMark value={0} mt='1' ml='-2.5' fontSize='sm'>
        0
      </SliderMark>
      <SliderMark value={1} mt='1' ml='-2.5' fontSize='sm'>
        1
      </SliderMark>
      <SliderMark value={2} mt='1' ml='-2.5' fontSize='sm'>
        2
      </SliderMark>
      <SliderMark value={3} mt='1' ml='-2.5' fontSize='sm'>
        3
      </SliderMark>
      <SliderMark value={4} mt='1' ml='-2.5' fontSize='sm'>
        4
      </SliderMark>
      <SliderMark value={5} mt='1' ml='-2.5' fontSize='sm'>
        5
      </SliderMark>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg='teal.500'
        color='white'
        placement='top'
        isOpen={showTooltip}
        label={`${rating}`}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  )
}


function FilterColumn() {
  return(
    <VStack mr='2%' width="100%" alignItems={'left'}>
    <Select placeholder='Select Option' variant='filled' onChange={handleSelectChange}>
  <option value='title'>Title</option>
  <option value='isbn'>ISBN</option>
  <option value='author'>Author</option>
</Select>

<Checkbox size="lg" mt="5%" onChange={e=>{setIsAvailableNow(!isAvailableNow); searchBooks(searchInput, selectOption, searchTagsList, !isAvailableNow, rating)}}> Available Now</Checkbox>
<HStack mt="2%">
<Text mr="4%" fontSize="lg">Rating{'>'}=</Text>
{SliderThumbWithTooltip()}
</HStack>


{SearchTagsList()}

</VStack>
  );
}

return(
<ChakraProvider >
<div height='100%' m='0'>
    <NavBar current_page={2}/>
    <Flex mb="5%">
    <VStack width="25%" pl = "3%">
{FilterColumn()}
    </VStack>

    <VStack width="75%" pl = "3%" pr = "3%">
      {SearchBar()}
      
<BookCards books = {bookCards}/>
    </VStack>

    </Flex>
  </div>
  </ChakraProvider>
);
}

export default Search;