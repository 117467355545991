import React from 'react';
import { useLocation, useNavigate, Navigate, useLoaderData} from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import Axios from 'axios';
import {Switch, HStack, Button, Portal, Popover, PopoverTrigger,PopoverContent, PopoverHeader,PopoverBody,PopoverFooter,PopoverArrow,PopoverCloseButton,PopoverAnchor,Center, useEditableControls, IconButton, Input, Editable,EditableInput,EditablePreview, ButtonGroup, Heading, Text, Flex, VStack, ChakraProvider } from '@chakra-ui/react';
import { DeleteIcon, CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import BookshelfBookCard from './components/bookshelfBookCard.tsx';
import BookCard from './components/bookcard.tsx';

interface Book {
    id: number;
    title: string;
    author: string;
}

interface BookshelfDisplayProps {
    books: Book[];
}

function BookshelfDisplay() {
    const userid: number = useLoaderData().userid as number;
    const location = useLocation();
    const navigate = useNavigate();
    const prevBookshelfName = useRef("");
    const [bookshelfName, setBookshelfName] = useState(useLoaderData().bookshelfName as string);
    
    const [isPublic, setIsPublic] = useState(true);
    const [numBooks, setNumBooks] = useState(0);
    const [numSaves, setNumSaves] = useState(0);
    const [username, setUsername] = useState("");

    const [books, setBooks] = useState([{}]);
    const [bookCards, setBookCards] = useState(BookshelfBookCard[""]);

    useEffect(() => {

        Axios.post("http://localhost:3001/getBookshelfMetadata", {
            userid: userid,
            bookshelfName: bookshelfName
         }).then((response) => {
           try{
            setIsPublic(response.data.message[0][0].is_public);
            setNumBooks(response.data.message[0][0].numBooks);
            setNumSaves(response.data.message[0][0].numSaves);
            setUsername(response.data.message[0][0].username);
           } catch (e) {
      
           }
         }); 

            Axios.post("http://localhost:3001/getBookshelfBooks", {
                userid: userid,
                bookshelfName: bookshelfName
            }).then((response) => {
                try{
                    console.log(response.data.message[0][0]);
                 setBooks(response.data.message[0]);
                } catch (e) {
           
                }
              }); 
          }, [location]);

      useEffect(() => {
        prevBookshelfName.current = bookshelfName; 
          }, [bookshelfName]);

    useEffect(() => {
        // if this is by owner
        if (books[0].isbn == undefined) {
            setBookCards([""]);
        }
else if (userid == localStorage.getItem("userid")) {

            setBookCards(books.map((book, index) => (
            <BookshelfBookCard key={index} isbn={book.isbn} title={book.title} author={book.authors} totalCopies={book.total_copies} availCopies={book.available_copies} tags={book.tags} rating={book.rating} add_date={book.add_date}/>
          )));
} else {
    setBookCards(books.map((book, index) => (
        <BookCard key={index} isbn={book.isbn} title={book.title} author={book.authors} totalCopies={book.total_copies} availCopies={book.available_copies} tags={book.tags} rating={book.rating}/>
      )));
}

    } , [books]);

          if (localStorage.getItem("token") == null) {
            // not logged in!
            //navigate("/login");
            return <Navigate to={"/login"} replace />;
          }  

      function BookshelfInfo() {
        
        if (userid == localStorage.getItem("userid")) {
            // user is viewing their own bookshelf
            
            function CustomInput() {
                function EditableControls() {
                  const {
                    isEditing,
                    getSubmitButtonProps,
                    getCancelButtonProps,
                    getEditButtonProps,
                  } = useEditableControls()
              
                  return isEditing ? (
                    <ButtonGroup justifyContent='left' size='sm'>
                      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
                      <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
                    </ButtonGroup>
                  ) : (
                    <Flex justifyContent='left'>
                      <IconButton size='sm' icon={<EditIcon />} {...getEditButtonProps()}/>
                        <DeleteButton/>
                    </Flex>
                  )
                }
        
                let handleSubmit= (e) => {
                    let inputValue = e;
                    setBookshelfName(inputValue);
                    editBookshelfName(inputValue);
                  }

                  function editBookshelfName(bookshelfName) {
                    Axios.post("http://localhost:3001/editBookshelfName", {
                        userid: localStorage.getItem("userid"),
                        newBookshelfName: bookshelfName,
                        oldBookshelfName: prevBookshelfName.current
                     }).then((response) => {
                 console.log(response);
                     });
                  }            
              
                return (
                  <Editable
                  width = "100%"
                    textAlign='left'
                    defaultValue={bookshelfName}
                    onSubmit={handleSubmit}
                    placeholder="Describe yourself here!"
                  >
                    <EditablePreview width="100%" fontSize = "4xl" fontWeight={"bold"}/>
                    {/* Here is the custom input */}
                    <Input 
                  as={EditableInput} maxLength={50}/>
                    <EditableControls />
                  </Editable>
                )
              }

              function DeleteButton() {
                const initRef = React.useRef()

                function deleteBookshelf() {
                    Axios.post("http://localhost:3001/deleteBookshelf", {
                        userid: localStorage.getItem("userid"),
                        bookshelfName: bookshelfName
                     }).then((response) => {
                 console.log(response);
                     });

                    navigate("/");
                    navigate(0);
                }

                return(
                    <Popover initialFocusRef={initRef}>
                        {({ isOpen, onClose }) => (
        <>
  <PopoverTrigger>
    <IconButton size='sm' aria-label='Delete Bookshelf' icon={<DeleteIcon />} ml="10px"/>
  </PopoverTrigger>
  <Portal>
    <PopoverContent width='220px'>
      <PopoverArrow />
      <PopoverHeader>Are you sure you want to delete this bookshelf?</PopoverHeader>
      <PopoverCloseButton />
      <PopoverBody>
        <Button colorScheme='teal' onClick={deleteBookshelf}>Yes</Button>
        <Button ml="10px" colorScheme='red' onClick={onClose} ref={initRef}>No</Button>
      </PopoverBody>
    </PopoverContent>
  </Portal>
  </>  )}
        </Popover>
                );}

                function changeVisibility() {
                    Axios.post("http://localhost:3001/changeVisibility", {
                        userid: localStorage.getItem("userid"),
                        bookshelfName: bookshelfName,
                        isPublic: !isPublic
                     }).then((response) => {
                 console.log(response);
                     });
                    setIsPublic(!isPublic);
                }

            return (
                <VStack alignItems={'left'}  width="100%">
                    <Center width="100%">
                        <CustomInput />
                    </Center>
                    <HStack alignItems={'left'} width="100%" mt="10px">
                        
                        <Text fontWeight={"medium"} fontSize={"xl"}>Visibility: {isPublic ? ("Public"): ("Private")}</Text>
                    <Switch ml = "5px" colorScheme='teal' size='lg' defaultChecked = {isPublic} onChange={changeVisibility}/>
                    </HStack>
                    <Text fontSize={"xl"}><b>{numBooks}</b> books</Text>
                    <Text fontSize={"xl"}><b>{numSaves}</b> saves</Text>
                </VStack>
            );
        } else {
            // user is viewing someone else's bookshelf
        return (
            <VStack>
                <Heading>{bookshelfName}</Heading>
                <Text fontSize={"xl"}>By <b>{username}</b></Text>
                <Text fontSize={"xl"}><b>{numBooks}</b> books</Text>
                    <Text fontSize={"xl"}><b>{numSaves}</b> saves</Text>
            </VStack>
        );
      }}

      function BookshelfBooks() {
        return(
            <Flex mt="2%" width="100%" flex='1' gap='5' flexWrap='wrap' alignItems={'flex-start'} justifyContent={'left'}>
            {bookCards}
        </Flex>
        );
      }

    return (
<ChakraProvider>
<div className="App" height='100%' m='0'>
    <Flex width="100%" mb="5%">
        <VStack width="25%" pl = "3%" mt="10px" >
        <BookshelfInfo />
        </VStack>
        <VStack width="75%" pl = "3%" pr = "3%" alignItems={'left'}>
        <BookshelfBooks />
        </VStack>
    </Flex>
      </div>
</ChakraProvider>
    );
};

export default BookshelfDisplay;