import React from 'react';
import { useState, useEffect } from 'react';
import NavBar from './components/navbar.tsx';
import { useLocation, Navigate, useLoaderData} from "react-router-dom";
import { Button, Select, Flex,Table,Thead,Tbody,Tfoot,Tr,Th,Td,TableCaption,TableContainer,Heading, Tag, TagLabel, HStack, VStack, Text,ChakraProvider } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import ReactStars from "react-rating-stars-component";
import Axios from 'axios';

const fetch = require('node-fetch');

interface Book {
    isbn: string
    title: string;
    authors: string;
    publish_date: string;
    tags: string;
    rating: number;
    available_copies: number;
    total_copies: number;
    synopsis: string;

    times_borrowed: number;
    no_saves: number;
    no_rates: number;
}

interface BookAvail {
    lib_name: string;
    available_copies: number;
    total_copies: number;
}

interface BookDisplayPageProps {
    book: Book;
}

interface BookAnalyticsPageProps {
    book: BookAvail[];
}

interface BookPersonalPageProps {
    rating: number;
    ratingButton: string;
}

const BookDisplayPage: React.FC<BookDisplayPageProps> = ({ book }) => {
    if (book != undefined) {
        function TagsList() {
            if (book.tags !== undefined) {
                const tag_list = book.tags.split(", ");
                return tag_list.map(tag => (
                    <Tag size="lg" key={tag}  colorScheme='cyan' borderRadius='full' mr="5px" mb="5px">
                        <TagLabel>{tag}</TagLabel>
                    </Tag>
                ));
            }
        }
    
        function Rating({ rating }: { rating: number }) {
            const stars = Array.from({ length: 5 }, (_, index) => (
                <Star key={index} filled={index+1 <= rating} />
            ));
    
            return <HStack>{stars}</HStack>;
        }
    
        function Star({ filled }: { filled: boolean }) {
            return (
                <span style={{ color: filled ? 'gold' : '#EDF2F7' }}>
                    <Text fontSize='xl' mr="10px">
                    <StarIcon/>
                    </Text>
                </span>
            );
        }


    return (
        <div>
            <Heading size='xl'> {book.title}</Heading>
                <Text fontWeight='medium' mt="5px" fontSize='2xl'>By {book.authors}</Text>
                <Text fontSize="lg" color="gray.500">ISBN: {book.isbn}</Text>
                <Text fontSize="lg" color="gray.500">Published on {book.publish_date.substring(0,10)}</Text>
                <Text mt="5px" mb = "12px" fontSize="lg">{book.synopsis}</Text>
            <TagsList />
            <Text mt="10px" fontSize="lg"><b>{book.available_copies}</b> / <b>{book.total_copies}</b> copies available</Text>
            <Text mt="10px" fontSize="lg" color="gray.500">This book was borrowed <b>{book.times_borrowed} </b>  times and saved <b>{book.no_saves}</b> times</Text>
                <HStack width="100%" justifyContent={"left"} alignContent={"left"}>
                    <Rating rating={book.rating} />
                    <Text fontWeight="medium" fontSize="xl" mt="5px">{book.rating}</Text>
                    <Text fontSize="lg" color="gray.500" ml="10px" mt="7px">{book.no_rates} ratings</Text>
                </HStack>
        </div>
    );}
};

const BookAnalyticsPage: React.FC<BookAnalyticsPageProps> = ({ book }) => {
    if (book != undefined) {
        const bookAvailList = book.map((bookAvail) => (
                <Tr key={bookAvail.lib_name}>
                    <Td>{bookAvail.lib_name}</Td>
                    <Td isNumeric>{bookAvail.available_copies}</Td>
                    <Td isNumeric>{bookAvail.total_copies}</Td>
                </Tr>
            ))

        return(
            <VStack mt="20px" alignItems={'left'}>
                <Text fontSize="2xl" fontWeight="medium">This book is available at {book.length} libraries</Text>
                <TableContainer>
  <Table variant='striped'>
    <Thead>
      <Tr>
        <Th>Library</Th>
        <Th isNumeric>Available Copies</Th>
        <Th isNumeric>Total Copies</Th>
      </Tr>
    </Thead>
    <Tbody>
        {bookAvailList}
        </Tbody>
  </Table>
</TableContainer>
            </VStack>
        );
    }
}

function BookDisplay() {
let isbn: String = useLoaderData() as string;
const location = useLocation();
const [book, setBook] = useState<Book | null>(null);
const [bookAvail, setBookAvail] = useState<BookAvail[]>([]);
const [prevRating, setPrevRating] = useState(-1);
const [rating, setRating] = useState(0);
const [ratingButton, setRatingButton] = useState("Add Rating");
const [forcedUpdate, forceUpdate] = React.useState(0);
const [bookUndefined, setBookUndefined] = React.useState(false);

useEffect(() => {

    Axios.post("http://localhost:3001/getMoreBookData", {
        isbn: isbn
     }).then((response) => {
       try{
         setBook(response.data.message[0][0])
         if (response.data.message[0][0] == undefined) {
            setBookUndefined(true);
        }
       } catch (e) {
console.log(e);
       }
     });

     Axios.post("http://localhost:3001/getBookAvail", {
        isbn: isbn
     }).then((response) => {
       try{
         setBookAvail(response.data.message[0]);
       } catch (e) {
console.log(e);
       }
     });

     Axios.post("http://localhost:3001/getUserRating", {
        userid: localStorage.getItem("userid"),
        isbn: isbn
     }).then((response) => {
       try{
        if (response.data.message[0] != undefined) {
            setRating(response.data.message[0].score);
        } 
       } catch (e) {
console.log(e);
       }
     });

  }, [location]);

if (localStorage.getItem("token") == null) {
    // not logged in!
    //navigate("/login");
    return <Navigate to={"/login"} replace />;
  }  

  if (bookUndefined) { 
    return <Navigate to={"/search"} replace />;
  }
// TODO: ADD ERROR PAGE IF ISBN NOT FOUND!


const BookPersonalPage: React.FC<BookPersonalPageProps> = ({ rating, ratingButton }) => {

    function Rating({rating}) {
        const ratingChanged = (newRating) => {
            setRating(newRating);
          };
        return(
            <ReactStars
            count={5}
            value={rating}
            onChange={ratingChanged}
            size={50}
            color="#EDF2F7"
            activeColor="gold"
            isHalf={true}
          />
        );
    }

    function handleRemoveRating() { 
        if (rating == 0) {
            return;
        }

        setRating(0);
        Axios.post("http://localhost:3001/removeRating", {
            userid: localStorage.getItem("userid"),
            isbn: isbn
         }).then((response) => {});

         console.log("loading more book data")
         Axios.post("http://localhost:3001/getMoreBookData", {
             isbn: isbn
          }).then((response) => {
            try{
              setBook(response.data.message[0][0]);
            } catch (e) {
     console.log(e);
            }
          });
          setRatingButton("Add Rating");
          forceUpdate(forcedUpdate+1); 
            }
       
    useEffect(() => {
                if (rating == 0) {
                } else {
                    if (ratingButton == "Add Rating") {
                        
                        // if it was previously unrated
                        Axios.post("http://localhost:3001/insertRating", {
                            userid: localStorage.getItem("userid"),
                            isbn: isbn,
                            rating: rating,
                         }).then((response) => {});

                         Axios.post("http://localhost:3001/getMoreBookData", {
                             isbn: isbn
                          }).then((response) => {
                            try{
                              setBook(response.data.message[0][0]);
                            } catch (e) {
                     console.log(e);
                            }
                          });

                          forceUpdate(forcedUpdate+1); 
                          
                    } else if (ratingButton == "Remove Rating" && prevRating != rating) {
                        console.log("Edtinig something")
                        Axios.post("http://localhost:3001/editRating", {
                            userid: localStorage.getItem("userid"),
                            isbn: isbn,
                            rating: rating,
                         }).then((response) => {});

                         Axios.post("http://localhost:3001/getMoreBookData", {
                             isbn: isbn
                          }).then((response) => {
                            try{
                              setBook(response.data.message[0][0]);
                            } catch (e) {
                     console.log(e);
                            }
                          });

                          forceUpdate(forcedUpdate+1); 
                          
                    } 
                    setRatingButton("Remove Rating");
                    }

                    if (prevRating != rating) {
                        setPrevRating(rating);
                    }
            }, [rating]);    

    //   <Select placeholder="Select Option" variant='filled' onChange={addBookshelf}>
    //       {filteredTags.map((tag, index) => (
    //         <option key={index} value={tag}>{tag}</option>
    //       ))}
    //     </Select>


  return (
    <div>
    <HStack className='App' width="100%">
    <Rating rating={rating}/>
  <Text fontWeight="medium" fontSize="xl" mt="5px">{rating}</Text>
    </HStack>
    <Button variant="outline" mt="1%" colorScheme='teal' size='md' onClick={handleRemoveRating}>{ratingButton}</Button>
    <Select mt = "5%" placeholder="Add to Bookshelf" variant='filled'>
            <option value={"bookshelf 1"}>Bookshelf 1</option>
        </Select>
    </div>
  )
}


    return(
<ChakraProvider>
<div className="App" height='100%' m='0'>
    <Flex width="100%" mb="5%">
        <VStack width="22%" pl = "3%" mt="4%" >
        <BookPersonalPage rating={rating} ratingButton={ratingButton}/>
        </VStack>
        <VStack width="78%" pl = "3%" pr = "3%" alignItems={'left'}>
            <BookDisplayPage book={book} />
            <BookAnalyticsPage book={bookAvail} />
        </VStack>
    </Flex>
      </div>
</ChakraProvider>
    );
}

export default BookDisplay;