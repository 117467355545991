import { useState } from 'react';
import React from 'react';
import { Link} from "react-router-dom";
import { HStack, Center, VStack, Text, Image, ChakraProvider } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import NavBar from './components/navbar.tsx';

import logo from './images/book-icon.svg';
import airbooks_photo from './images/nushigh-airbooks-photo.png';

function About() {
return (
<ChakraProvider >
<div className="App" height='100%' m='0'>
    
<NavBar current_page={3} />
    <Center>
        <VStack width="60%" mt="5%"> 

        <Text fontSize='3xl' fontWeight='medium'>
            AirBooks Database, made by Kai Wen in 2024 for CS6131.
        </Text>

        <Text fontSize='2xl' fontWeight='medium'>Credits:</Text>

        <Text fontSize='xl' fontWeight='medium'>
        No AI was used in the creation of this website. <br /></Text>

<HStack>
<Text color="#00A39C" fontSize='xl' fontWeight='medium' >
        <Link to='https://react.dev/' isExternal>React<ExternalLinkIcon mx='2px' /></Link> 
        </Text> <Text fontSize='xl' fontWeight='medium'> to build this site. </Text>
        
</HStack>

<HStack>
<Text color="#00A39C" fontSize='xl' fontWeight='medium' >
        <Link to='https://chakra-ui.com/' isExternal>Chakra UI<ExternalLinkIcon mx='2px' /></Link> 
        </Text> <Text fontSize='xl' fontWeight='medium'> for frontend components. </Text>
        
</HStack>
        
        </VStack>
    </Center>
    </div>
</ChakraProvider>
  );
}

export default About;