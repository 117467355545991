import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import About from "./routes/about.tsx";
import Root from "./routes/root.tsx";
import Login from "./routes/login.tsx";
import Search from "./routes/search.tsx";
import ErrorPage from "./routes/error-page.tsx";
import Profile from './routes/profile.tsx';
import Book from './routes/book.tsx';
import BookDisplay from './routes/bookDisplay.tsx';
import Bookshelf from './routes/bookshelf.tsx';
import BookshelfDisplay from './routes/bookshelfDisplay.tsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "book",
    element: <Book />,
    children: [
      {
        path: "/book/:isbn",
        element: <BookDisplay />,
        loader: ({ params }) => {
          return params.isbn;
        },     
      },
    ],
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "search",
    element: <Search />,
  },
  {
    path: "bookshelf",
    element: <Bookshelf/>,
    children: [
      {
        path: "/bookshelf/:userid/:bookshelfName",
        element: <BookshelfDisplay />,
        loader: ({ params }) => {
          return params;
        },     
      },
    ]
  }
], { basename: process.env.PUBLIC_URL,});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);