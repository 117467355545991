import React from 'react';
import { useState } from 'react';
import { LinkBox, LinkOverlay, VStack, HStack, Tag, TagLabel, Heading, Text,  Button, Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react';
import {Link} from "react-router-dom";
import { StarIcon } from '@chakra-ui/icons'
import Axios from 'axios';

interface BookCardProps {
    isbn: string;
    title: string;
    author: string;
    tags: string;
    rating: number;
    availCopies: number;
    totalCopies: number;
}

const BookCard: React.FC<BookCardProps> = ({ isbn, title, author, tags, rating, availCopies, totalCopies }) => {

    function TagsList() {
        if (tags !== undefined) {
            const tag_list = tags.split(", ");
            return tag_list.map(tag => (
                <Tag key={tag}  colorScheme='cyan' borderRadius='full' mr="5px" mb="5px">
                    <TagLabel>{tag}</TagLabel>
                </Tag>
            ));
        }
    }

    function Rating({ rating }: { rating: number }) {
        const stars = Array.from({ length: 5 }, (_, index) => (
            <Star key={index} filled={index+1 <= rating} />
        ));

        return <HStack>{stars}</HStack>;
    }

    function Star({ filled }: { filled: boolean }) {
        return (
            //<StarIcon color={ {color: filled ? 'gold' : 'white' }}/>
            
                //{filled ? '★' : '☆'}
            <span style={{ color: filled ? 'gold' : '#EDF2F7' }}  >
                <Text fontSize='sm'>
                <StarIcon/>
                </Text>
            </span>
        );
    }

    return (
        <LinkBox >
        <Card maxW="sm" size='sm' width="285px" height="250px" borderRadius='lg' variant='outline' >
            <CardHeader pb="0px">
            <LinkOverlay as={Link} to={`/book/`+isbn}>
                <Heading size='md'> {title}</Heading>
                <Text>By {author}</Text>
                <Text color="gray.500">ISBN: {isbn}</Text>
                </LinkOverlay>
            </CardHeader>
            <CardBody pt = "15px" pb="0px" height="auto">
                <TagsList />
            </CardBody>
            <CardFooter pt="0px">
                <VStack justifyContent={"left"} alignContent={"left"}>
                <Text><b>{availCopies}</b> / <b>{totalCopies}</b> copies available</Text>
                <HStack width="100%" justifyContent={"left"} alignContent={"left"}>
                    <Rating rating={rating} />
                    <Text fontSize="lg" ml = "5px" mt="2px">{rating}</Text>
                </HStack>
                </VStack>
            </CardFooter>
        </Card>
        </LinkBox>
    );
};

export default BookCard;