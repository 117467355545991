import { useEffect, useState } from 'react';
import React from 'react';
import { Link, useNavigate, Navigate, useLocation} from "react-router-dom";
import {TableContainer, Table, Thead, Tr, Th, Td, Tbody, Tabs, TabList, TabPanels, Tab, TabPanel, Textarea, Grid, GridItem , Input, useEditableControls, IconButton, Flex, ButtonGroup, HStack, Center, VStack, Text, Image, ChakraProvider, Editable,EditableInput,EditableTextarea,EditablePreview, } from '@chakra-ui/react'
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'
import NavBar from './components/navbar.tsx';
import Axios from 'axios';

interface CurrentlyReadingRecord {
  isbn: string,
  title: string,
  lib_name: string;
  borrow_date: string;
  due_date: string;
}

interface BorrowingHistoryRecord {
  isbn: string,
  title: string,
  lib_name: string;
  borrow_date: string;
  return_date: string;
}


interface CurrentlyReadingPageProps {
  records: CurrentlyReadingRecord[];
}

interface BorrowingHistoryPageProps {
  records: BorrowingHistoryRecord[];
}

const CurrentlyReadingPage: React.FC<CurrentlyReadingPageProps> = ({ records }) => {
  if (records != undefined) {
    records = Array.isArray(records) ? records : [records];
    const currentRecords = records.map((record) => (
      <Tr key={record.isbn}>
        <Td>{record.isbn}</Td>
        <Td>{record.title}</Td>
        <Td>{record.lib_name}</Td>
        <Td>{new Date(record.borrow_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</Td>
        <Td>{new Date(record.due_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</Td>
      </Tr>
    ));

    return (
<TableContainer>
      <Table variant='striped'>
      <Thead>
        <Tr>
          <Th>ISBN</Th>
          <Th>Title</Th>
          <Th>Library</Th>
          <Th>Borrow Date</Th>
          <Th>Due Date</Th>
        </Tr>
      </Thead>
      <Tbody>
          {currentRecords}
          </Tbody>
    </Table>
  </TableContainer>
    );
  }
}


const BorrowingHistoryPage: React.FC<BorrowingHistoryPageProps> = ({ records }) => {
  if (records != undefined) {
    records = Array.isArray(records) ? records : [records];
    const borrowedRecords = records.map((record) => (
      <Tr key={record.isbn}>
        <Td>{record.isbn}</Td>
        <Td>{record.title}</Td>
        <Td>{record.lib_name}</Td>
        <Td>{new Date(record.borrow_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</Td>
        <Td>{new Date(record.return_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</Td>
      </Tr>
    ));

    return (
<TableContainer>
      <Table variant='striped'>
      <Thead>
        <Tr>
          <Th>ISBN</Th>
          <Th>Title</Th>
          <Th>Library</Th>
          <Th>Borrow Date</Th>
          <Th>Return Date</Th>
        </Tr>
      </Thead>
      <Tbody>
          {borrowedRecords}
          </Tbody>
    </Table>
  </TableContainer>
    );
  }
}


function Profile() {
    const [username, setUsername] = useState(localStorage.getItem("username"));
    const [usernameDesc, setUsernameDesc] = useState("");
    const location = useLocation();
    const [currentlyReadingRecords, setCurrentlyReadingRecords] = useState<CurrentlyReadingRecord[]>([]);
    const [borrowingHistoryRecords, setBorrowingHistoryRecords] = useState<BorrowingHistoryRecord[]>([]);


    useEffect(() => {

        Axios.post("http://localhost:3001/profile", {
           username: localStorage.getItem("username"),
        }).then((response) => {
          try{
            setUsernameDesc(response.data.message[0].user_desc);
          } catch (e) {

          }
        });

        Axios.post("http://localhost:3001/getCurrentlyReading", {
          userid: localStorage.getItem("userid"),
       }).then((response) => {
         try{
           setCurrentlyReadingRecords(response.data.message[0]);
         } catch (e) {

         }
       });

       Axios.post("http://localhost:3001/getBorrowHistory", {
        userid: localStorage.getItem("userid"),
     }).then((response) => {
       try{
         setBorrowingHistoryRecords(response.data.message[0]);
       } catch (e) {

       }
     });
       
      }, [location]);

    // if not logged in, redirect to login page.
    if (localStorage.getItem("token") == null) {
        // not logged in!
        //navigate("/login");
        return <Navigate to={"/login"} replace />;
      }

//      const profile = () => {
       


    function editDescription(usernameDesc) {
        console.log("edit profile called")
        Axios.post("http://localhost:3001/editProfile", {
            username: localStorage.getItem("username"),
            user_desc: usernameDesc
         }).then((response) => {
     console.log(response);
             console.log("update fr")
         });
      }



      function CustomControlsExample() {
        /* Here's a custom control */
        function EditableControls() {
          const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
          } = useEditableControls()
      
          return isEditing ? (
            <ButtonGroup justifyContent='left' size='sm'>
              <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
              <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
            </ButtonGroup>
          ) : (
            <Flex justifyContent='left'>
              <IconButton size='sm' icon={<EditIcon />} {...getEditButtonProps()} />
            </Flex>
          )
        }

        let handleSubmit= (e) => {
            let inputValue = e;
            setUsernameDesc(inputValue);
            editDescription(inputValue);
          }
    
      
        return (
          <Editable
            width="80%"
            textAlign='left'
            defaultValue={usernameDesc}
            onSubmit={handleSubmit}
            placeholder="Describe yourself here!"
            fontSize='md'
          >
            <EditablePreview width="100%"/>
            {/* Here is the custom input */}
            <Input 
          as={EditableInput} maxLength={200}/>
            <EditableControls />
          </Editable>
        )
      }

return (
<ChakraProvider >
<div className="App" height='100%' m='0'>
    
<NavBar current_page={4}  />

<Flex>


<VStack width="30%" mt="2%" pl="5%">

<Text width="100%" fontSize='4xl' fontWeight='medium'>
{username} <br /></Text>

<Center width="100%" justifyContent={'left'}>
    
<CustomControlsExample />
</Center>

</VStack>


<VStack width="70%">

<Tabs width = "100%" isFitted isLazy defaultIndex={0} colorScheme='teal' mr="5%">
  <TabList>
    <Tab>Currently Reading</Tab>
    <Tab>Borrow History</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
      <CurrentlyReadingPage records= {currentlyReadingRecords}  />
    </TabPanel>
    <TabPanel>
      <BorrowingHistoryPage records = {borrowingHistoryRecords}/>
    </TabPanel>
  </TabPanels>
</Tabs>
</VStack>

</Flex>


    </div>
</ChakraProvider>
  );
}


export default Profile;