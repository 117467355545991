import { useState } from 'react';
import React from 'react';
import { Link, useNavigate} from "react-router-dom";
import logo from './../images/book-icon.svg';
import { Button, Text, Image, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react'
import Axios from 'axios';

export default function NavBar({current_page}) {
  const [loginStatus, setLoginStatus] = useState("token" in localStorage);
  const [profileName, setProfileName] = useState("Profile");
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
    setLoginStatus(false);
    navigate("/login");
  }

  function Loginout({loginStatus}) {
    if ("token" in localStorage) { // if currently logged in
      setProfileName(localStorage.getItem("username"));
      setLoginStatus(true);
      return(
        <Button colorScheme='teal' pos="absolute" right="8" onClick= {logout}>
          Log out</Button>
      );
  } else {
    setProfileName("Profile");
    setLoginStatus(false);
    return(
      <Button colorScheme='teal' pos="absolute" right="8" as={Link} to='../login'>
        Log in</Button>
    );
    
  }
  }


  function CustomBreadcrumbItem({ isCurrentPage, pageName, pageLink }) {
    if (isCurrentPage == false) {
      return (
      <BreadcrumbItem ml = '7' _hover={{color: "#00A39C" }} color="">
        <BreadcrumbLink as={Link} to={pageLink} textDecoration='none'> {pageName} </BreadcrumbLink>
      </BreadcrumbItem>
      );
    } else {
    return (
      <BreadcrumbItem ml = '7' isCurrentPage color="#00A39C">
        <BreadcrumbLink as={Link} to={pageLink} textDecoration='none'> {pageName} </BreadcrumbLink>
      </BreadcrumbItem>
    );
    }
  }

  var breadcrumb1 = false;
  var breadcrumb2 = false;
  var breadcrumb3 = false;
  var breadcrumb4 = false;
  if (current_page == '1') {
    breadcrumb1 = true;
  } else if (current_page == '2') {
    breadcrumb2 = true;
  } else if (current_page == '3'){
    breadcrumb3 = true;
  } else if (current_page == '4') {
    breadcrumb4 = true;
  }

    return (
    <HStack fontWeight='medium' fontSize='xl' position="sticky" top= "0" backgroundColor={"white"} zIndex={20}>
      <Link to={'/'}>
      <Image 
        ml = '10'
        mt = '3'
        borderRadius='full'
        boxSize='75px'
        src={logo}
        alt='AirBooks Logo'
      />
      </Link>

    
      <Text fontSize='4xl' color="#00A39C" ml='-1.5' as={Link} to={"/"}>
        AirBooks
      </Text>
    
      <Breadcrumb separator=' ' pos="absolute" right="140">
        <CustomBreadcrumbItem isCurrentPage={breadcrumb1} pageName="Home" pageLink="/"/>
        <CustomBreadcrumbItem isCurrentPage={breadcrumb2} pageName="Search" pageLink="/search"/>
        <CustomBreadcrumbItem isCurrentPage={breadcrumb3} pageName="About" pageLink="/about"/>
        <CustomBreadcrumbItem isCurrentPage={breadcrumb4} pageName={profileName} pageLink="/profile"/>
      </Breadcrumb>
    
      <Loginout loginStatus = {loginStatus}/>
    
    </HStack>
      );
}
