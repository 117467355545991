import React from 'react';
import NavBar from './components/navbar.tsx';
import {Outlet} from "react-router-dom";
import {ChakraProvider } from '@chakra-ui/react';

export default function Book() {
    return(
        <ChakraProvider >
        <div className="App" height='100%' m='0'>
        <NavBar current_page={1} />
        <Outlet />
        </div>
    </ChakraProvider>
    );
};