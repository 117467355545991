import { useState, useEffect } from 'react';
import React from 'react';
import {Outlet, Link, Navigate, useLocation} from "react-router-dom";
import { VStack, Box, Flex, Spacer, Button, Text, Image, HStack, ChakraProvider} from '@chakra-ui/react'
import NavBar from './components/navbar.tsx';
import logo from './images/book-icon.svg';
import book_man from './images/man-reading.svg';
import BookshelfCard from './components/bookshelfCard.tsx';
import Axios from 'axios';

function Body() {

  function CustomGreeting() {
    if (localStorage.getItem("token") != null) {
        return(

          <Text fontWeight='medium' fontSize='6xl'>
        Hello, {localStorage.getItem("username")}
          </Text>
        )
    }
  }
// <Outlet />
  return (

      <Box pl = '10' width='50%' mt='10' ml='5' p='10'>
        
        <CustomGreeting />
        <Text fontWeight='medium' fontSize='6xl'>
          All your books in 1 app.
          </Text>
          <Text fontWeight='medium' fontSize='2xl' mt='5'>
          Borrowing e-books made easy. Start reading with AirBooks today.
          </Text>
      
      <HStack mt='10'>
        <Button colorScheme='teal' size='lg' as={Link} to={`search`}>Start Reading</Button>
        {localStorage.getItem("token") == null ? (
<Button colorScheme='teal' size='lg' ml='5' as={Link} to={`login`} >Log in</Button>
        ):(
          <span />
        ) }
        
      </HStack>
      
      </Box>

  );
  }

  function BookshelfDisplay({bookshelves, savedBookshelves}) {
    if (bookshelves != undefined) {
      const bookshelfCards = bookshelves.map((bookshelf, index) => (
        <BookshelfCard key={index} bookshelfName = {bookshelf.bookshelfName} userid = {localStorage.getItem("userid")} username = {localStorage.getItem('username')} numBooks = {bookshelf.numBooks} numSaves = {bookshelf.numSaves} is_public = {bookshelf.is_public} is_owner={true} />
      ));

      const savedBookshelfCards = savedBookshelves.map((bookshelf, index) => (
        <BookshelfCard key={index} bookshelfName = {bookshelf.bookshelfName} userid = {bookshelf.user_id} username = {bookshelf.username} numBooks = {bookshelf.numBooks} numSaves = {bookshelf.numSaves} is_public = {bookshelf.is_public} is_owner={false} />
      ));
      return(
        <VStack alignItems = {'left'} mt='3%' ml='5%' mb="5%">
          <Text fontSize="5xl" fontWeight="medium" mb="15px">Your Bookshelves</Text>
          <Flex width="100%" flex='1' gap='8' flexWrap='wrap' alignItems={'flex-start'} justifyContent={'left'}>
  {bookshelfCards}
     </Flex>

     <Text fontSize="5xl" fontWeight="medium" mb="15px" mt="50px">Your Saved Bookshelves</Text>
          <Flex width="100%" flex='1' gap='8' flexWrap='wrap' alignItems={'flex-start'} justifyContent={'left'}>
  {savedBookshelfCards}
     </Flex>
        </VStack>

      );
    }
  } 

function Root() {
  const [bookshelves, setBookshelves] = useState([{}]);
  const [savedBookshelves, setSavedBookshelves] = useState([{}]);
  const location = useLocation();

  useEffect(() => {

    Axios.post("http://localhost:3001/getBookshelves", {
      userid: localStorage.getItem("userid"),
   }).then((response) => {
     try{
      setBookshelves(response.data.message[0]);
     } catch (e) {

     }
   });

   Axios.post("http://localhost:3001/getSavedBookshelves", {
    userid: localStorage.getItem("userid"),
 }).then((response) => {
   try{
    setSavedBookshelves(response.data.message[0]);
   } catch (e) {

   }
 });
  
  }, [location]);

  return (
    <ChakraProvider >
      <div className="App" height='100%' m='0'>
      <NavBar current_page={1} />
      <Flex width="100%" height="100%">
        <Body />

        <Spacer />

        <Box width='50%' p='0'>
        <Image
        boxSize='90%'
        src={book_man}
        alt='Man reading'
        />
        </Box>
        </Flex>
<BookshelfDisplay bookshelves = {bookshelves} savedBookshelves={savedBookshelves}/>
      </div>
    </ChakraProvider>
  );
}

export default Root;
