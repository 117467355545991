import React from 'react';
import { useState } from 'react';
import { LinkBox, LinkOverlay, VStack, HStack, Tag, TagLabel, Heading, Text,  Button, Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react';
import {Link} from "react-router-dom";
import Axios from 'axios';

interface BookshelfProps {
    bookshelfName: string;
    userid: number;
    username: string;
    numBooks: number;
    is_public: boolean;
    numSaves: number;
    is_owner: boolean;
}

const BookshelfCard: React.FC<BookshelfProps> = ({ bookshelfName, userid, username, numBooks, numSaves, is_public, is_owner }) => {
    return (
        <LinkBox >
        <Card maxW="300px" size='sm' minW = "200px" p="20px" borderRadius='lg' variant='filled' >
            <CardHeader pb="0px">
            <LinkOverlay as={Link} to={`/bookshelf/`+userid+`/`+bookshelfName}>
                <Heading size='md'> {bookshelfName}</Heading>
                {is_owner ? (
                    <Text color="gray.500">Visibility: {is_public ? "Public" : "Private"}</Text>
                ) : (
                    <Text>By {username}</Text>
                )}
                </LinkOverlay>
            </CardHeader>
            <CardFooter height="auto">
                <VStack>
                    <Text fontSize="lg"><b>{numBooks}</b> books</Text>
                    <Text fontSize="lg" mt = "-10px" ml="-7px"><b>{numSaves}</b> saves</Text>
\
                
                </VStack>
            </CardFooter>
        </Card>
        </LinkBox>
    );
};

export default BookshelfCard;