import { useState, useEffect } from 'react';
import React from 'react';
import { Link, redirect, useNavigate, Navigate, useLocation} from "react-router-dom";
import NavBar from './components/navbar.tsx';
import { Show, Alert, AlertIcon, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Input, FormControl, FormLabel, Center, VStack, Button, ChakraProvider} from '@chakra-ui/react'
import Axios from 'axios';

import logo from './images/book-icon.svg';

function Login() {
  const isLoggedIn = localStorage.getItem("token");
  const location = useLocation();

    if (isLoggedIn) {
      return <Navigate to={"/"} replace />;
    }


return (
<ChakraProvider >
    <NavBar current_page={0} />
    <Center>
      <Tabs isFitted isLazy defaultIndex={0} width="40%" mt="5%" colorScheme='teal'>
        <TabList>
          <Tab>Login</Tab>
          <Tab>Register</Tab>
        </TabList>
      <TabPanels>
        <TabPanel>
          <Login_tab />
      </TabPanel>
        <TabPanel>
          <Register_tab />
        </TabPanel>
        </TabPanels>
      </Tabs>
  </Center>
</ChakraProvider>
);
}

function Login_tab() {
  const [usernameLogin, setUsernameLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState ("");
  const [loginStatus, setLoginStatus] = useState(-1);
  const [loginMessage, setLoginMessage] = useState("");
  const navigate = useNavigate();

  const login = () => {
    Axios.post("http://localhost:3001/login", {
       username: usernameLogin,
       password: passwordLogin,
    }).then((response) => {
       if (!response.data.auth) { //error
        console.log(response.data.message);
        
        setLoginStatus (0);
          setLoginMessage( response.data.message);
       } else { //success
          
          setLoginStatus(1);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("username", usernameLogin);
          localStorage.setItem("userid", response.data.result[0].user_id);
          setLoginMessage ("Successfully logged in!!!!");

          navigate("/");
       }
    });
    
    };

  function CustomAlert({status}) {
    //status == -1 is error
    if (status == 0){ //error 
      return (
          <Alert status='error'>
            <AlertIcon />
            {loginMessage}
          </Alert>
        );
    }
    else if (status == 1 ){ //success
      return (
        <Alert status='success'>
        <AlertIcon />
        {loginMessage}
      </Alert>
      );
    }

  };

  return (
    <VStack >
    <FormControl className="login">
      <FormLabel>Username</FormLabel>
      <Input placeholder="username" maxLength={30} onChange={e=> setUsernameLogin(e.target.value)} />

      <FormLabel mt="3%">Password</FormLabel>
      <Input type="password" placeholder="password"  onChange={e=> setPasswordLogin(e.target.value)} />
    </FormControl>

    <Button colorScheme='teal' size='md' ml='82%' mt="3%" as={Link}  onClick={login}>Log in</Button>
    <CustomAlert status={loginStatus} />

  </VStack>
  );
}

function Register_tab() {
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState ("");
  const [registerStatus, setRegisterStatus] = useState(-1);
  const [registerMessage, setRegisterMessage] = useState("");

  const register = () => {
    setRegisterStatus(-1); //hide
    setRegisterMessage("");

    console.log("registered post");
    // validate registration
    let username_pattern = /^[A-Za-z0-9 _-]{1,}$/;
    let username_valid = username_pattern.test(usernameReg);
    let password_valid = passwordReg.length >= 6;
    console.log(usernameReg, username_valid, password_valid);

    if (username_valid && password_valid) {
      Axios.post("http://localhost:3001/register", {
        username: usernameReg,
        password: passwordReg,
       }).then((response) => {
        console.log(response);
          if (response.data.message != "Success") {
          // if failed
            setRegisterStatus(0);
            setRegisterMessage(response.data.message);
          }
          else {
            // passed!
            setRegisterStatus(1);
            setRegisterMessage("Successfully registered. You may now log in.");
          }
       });
    } else if (!username_valid) {
      setRegisterStatus(0);
      setRegisterMessage("Username invalid! Must contain only letters, numbers, spaces, underscores, and hyphens.");
    } else if (!password_valid) {
      setRegisterStatus(0);
      setRegisterMessage("Password too short - it must be at least 6 characters long!");
    }
   };

   function CustomAlert({status}) {
    //status == -1 is error
    if (status == 0){ //error 
      return (
          <Alert status='error'>
            <AlertIcon />
            {registerMessage}
          </Alert>
        );
    }
    else if (status == 1 ){ //success
      return (
        <Alert status='success'>
        <AlertIcon />
        {registerMessage}
      </Alert>
      );
    }
  }

return (
  <VStack >
  <FormControl className="registration">
    <FormLabel>Username</FormLabel>
    <Input placeholder="username" maxLength={30} onChange={e=> setUsernameReg(e.target.value)}/>
    
    <FormLabel mt="3%">Password</FormLabel>
    <Input type="password" placeholder="password" onChange={e=> setPasswordReg(e.target.value)} />
  </FormControl>

  <Button colorScheme='teal' size='md' ml='82%' mt="3%" as={Link}  onClick={register}>Register</Button>
  <CustomAlert status={registerStatus} />
</VStack>
);
}


export default Login;